import React from 'react';
import './DetailModal.scss';
import { I18n } from 'react-redux-i18n';
import Button from '../../../../common/components/Button';
import { SimpleModal } from '../../../../common/components/Modal';
import CustomSelect from '../../../../common/components/CustomSelect';
import TextArea from '../../../../common/components/TextArea/TextArea';
import { parseDate } from '../../../../utils';
import { zeroPad } from '../../../../utils/various';
import { useHistory } from 'react-router-dom';
import { encryptString } from '../../../../utils/encryption';

const DetailModal = ({ show, data, close, postAuditorTransactions, setAuditorPatientDetail }) => {
    const history = useHistory();

    const [comment, setComment] = React.useState('');
    // Estado para manejar como cerrar el modal
    const [closeLock, setCloseLock] = React.useState(false);

    const [disabledComments, setDisableComments] = React.useState(true);

    return (
        <SimpleModal
            show={show}
            className='no-padding auto lg-radius-top'
            close={() => !closeLock && close()}
        >
            <div
                className='OSAudit-detail-modal'
                onMouseOver={() => !closeLock && setCloseLock(true)}
                onMouseLeave={() => setCloseLock(false)}
            >
                {/* Color bar */}
                <div className={`OSAudit-colorbar-detail ${data.stateLabel}`}>
                    <div className='OSAudit-colorbar-patient'>
                        <div className='OSAudit-colorbar-circle'>{data.id}</div>
                        {I18n.t('transaction.detailModal.colorbar.patient')}
                        <span className='bold number'>&nbsp;{data.paciente?.id}</span>
                        <span className='bold'>|&nbsp;{data.paciente?.nombre}</span>
                    </div>
                    <div className='OSAudit-colorbar-dni box'>
                        {I18n.t('transaction.detailModal.colorbar.dni')}
                        <span className='bold'>&nbsp;{data.paciente?.dni}</span>
                    </div>
                    <div className='box'>
                        {I18n.t('transaction.detailModal.colorbar.nro_afiliado')}
                        <span className='bold'>&nbsp;{data.paciente?.affiliate}</span>
                    </div>
                </div>

                {/* Header */}
                <div className='OSAudit-header'>
                    <div className='OSAudit-box'>
                        <span>{I18n.t('transaction.detailModal.header.contract')}</span>
                        <span className='bold'>
                            {zeroPad(data.contrato?.id, 3)} | {data.contrato?.descripcion}
                        </span>
                    </div>
                    <div className='OSAudit-box'>
                        <span>{I18n.t('transaction.detailModal.header.contract_type')}</span>
                        <span className='bold'>{data.contrato?.tipo_contrato_descr}</span>
                    </div>
                    <div className='OSAudit-box'>
                        <span>{I18n.t('transaction.detailModal.header.cicle')}</span>
                        <span className='bold'>{data.aplicacion}</span>
                    </div>
                </div>
                <hr />

                {/* Audit group */}
                <div className='OSAudit-auditgroup'>
                    <div className='box'>
                        <span className='body-title'>
                            {I18n.t(
                                data.stateLabel === 'auditRejected' ||
                                    data.stateLabel === 'cleanedEvidence' ||
                                    data.stateLabel === 'auditApproved' ||
                                    data.stateLabel === 'auditConsumptionRejected'
                                    ? 'transaction.detailModal.auditGroup.comment_history'
                                    : 'transaction.detailModal.auditGroup.auditGroup',
                            )}
                        </span>
                    </div>
                    <div className='box'>
                        <button
                            className='OSAudit-reportbutton'
                            onClick={() => {
                                setAuditorPatientDetail({ _original: data });
                                history.push('/auditHistory');
                            }}
                        >
                            {I18n.t('transaction.detailModal.auditGroup.report_button')}
                        </button>
                    </div>
                    {(data.stateLabel === 'auditRequired' ||
                        data.stateLabel === 'auditPending') && (
                        <div>
                            <CustomSelect
                                options={[
                                    {
                                        value: data.grupo_auditor_descr,
                                        label: data.grupo_auditor_descr,
                                    },
                                ]}
                                placeholder=''
                                value={data.grupo_auditor_descr}
                                onSelect={(v) => {}}
                                className='rounded i-screen'
                                error={false}
                            />
                        </div>
                    )}
                </div>
                {(data.stateLabel === 'auditRequired' || data.stateLabel === 'auditPending') && (
                    <hr />
                )}

                {/* Comment section */}
                <div className='OSAudit-commentsection'>
                    <span className='body-title'>
                        {data.stateLabel === 'auditRequired' &&
                            I18n.t('transaction.detailModal.commentSection.add_comment')}
                        {data.stateLabel === 'auditPending' &&
                            I18n.t('transaction.detailModal.commentSection.comment')}
                    </span>
                    {(data.stateLabel === 'auditRejected' ||
                        data.stateLabel === 'cleanedEvidence' ||
                        data.stateLabel === 'auditApproved' ||
                        data.stateLabel === 'auditConsumptionRejected') &&
                        [data.audit_historial[data.audit_historial.length - 1]].map((audit) => {
                            return (
                                <TextArea
                                    className='extra-padding-top'
                                    key={audit.id}
                                    label={
                                        <>
                                            {data.stateLabel === 'auditRejected' ||
                                            data.stateLabel === 'cleanedEvidence' ? (
                                                <button className='auditrejected-button' disabled>
                                                    {data.stateLabel === 'auditRejected'
                                                        ? I18n.t(
                                                              'transaction.detailModal.commentSection.audit_rejected',
                                                          )
                                                        : I18n.t(
                                                              'transaction.detailModal.commentSection.cleanedEvidence',
                                                          )}
                                                </button>
                                            ) : (
                                                <br />
                                            )}
                                            {audit.usuario_auditor
                                                ? data.grupo_auditor_descr
                                                : data.obra_social.razon_social}
                                        </>
                                    }
                                    value={data.audit_historial
                                        .map((audit) => audit.comentario_enc)
                                        .filter((comment) => comment)
                                        .join('\n')}
                                    error={
                                        data.stateLabel === 'auditRejected' ||
                                        data.stateLabel === 'cleanedEvidence'
                                            ? true
                                            : false
                                    }
                                    disabled
                                    style={{
                                        background: 'white',
                                        borderWidth: '2px',
                                        borderRadius: '15px',
                                    }}
                                />
                            );
                        })}
                    {data.stateLabel !== 'auditApproved' &&
                        data.stateLabel !== 'auditConsumptionRejected' && (
                            <div style={{ position: 'relative' }}>
                                <TextArea
                                    className='TextArea extra-padding-top label-mt comment-textarea'
                                    label={`${parseDate(new Date(data.updated_at))} | ${
                                        data.paciente?.nombre ?? data.paciente?.name
                                    } | ${data.obra_social?.razon_social ?? data.obra_social}`}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    error={false}
                                    disabled={disabledComments}
                                    placeholder='Sin comentarios..'
                                />
                                {(data.stateLabel === 'auditRejected' ||
                                    data.stateLabel === 'cleanedEvidence') && (
                                    <button
                                        className='addcomment-button'
                                        onClick={() => setDisableComments(false)}
                                    >
                                        {I18n.t(
                                            'transaction.detailModal.commentSection.addcomment_button',
                                        )}
                                    </button>
                                )}
                            </div>
                        )}
                </div>

                {/* Footer */}
                <div className={`OSAudit-footer ${data.stateLabel}`}>
                    {data.stateLabel === 'auditRequired' && (
                        <Button
                            className='primary share'
                            text={I18n.t('transaction.detailModal.footer.sharebutton')}
                            onClick={close}
                        />
                    )}
                    {(data.stateLabel === 'auditPending' ||
                        data.stateLabel === 'auditApproved' ||
                        data.stateLabel === 'auditConsumptionRejected') && (
                        <Button
                            className={`primary back ${
                                (data.stateLabel === 'auditApproved' ||
                                    data.stateLabel === 'auditConsumptionRejected') &&
                                'gradient'
                            }`}
                            text={I18n.t('transaction.detailModal.footer.back')}
                            onClick={close}
                        />
                    )}
                    {(data.stateLabel === 'auditRejected' ||
                        data.stateLabel === 'cleanedEvidence') && (
                        <>
                            <Button
                                className='primary resend'
                                text={I18n.t('transaction.detailModal.footer.resend')}
                                onClick={() => {
                                    if (!disabledComments) {
                                        postAuditorTransactions(
                                            {
                                                comentario_enc: encryptString(
                                                    comment,
                                                    data.paciente.cse,
                                                ),
                                                usuario_os: data.contrato.obra_social,
                                                estado: 'P',
                                            },
                                            data.id,
                                        );
                                        close();
                                    }
                                }}
                            />
                            <Button
                                className='primary back'
                                text={I18n.t('transaction.detailModal.footer.back')}
                                onClick={close}
                            />
                        </>
                    )}
                </div>
            </div>
        </SimpleModal>
    );
};

export default DetailModal;

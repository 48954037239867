import { GET_IS_WHITE_LISTER_RESPONSE, LOGOUT } from '../session/session.actions';
import {
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_USER_RESPONSE,
    SAVE_KEYS,
    SAVE_KEYS_RESPONSE,
    SAVE_KEYS_ERROR,
    VALIDATE_PASSWORD,
    VALIDATE_PASSWORD_RESPONSE,
    VALIDATE_PASSWORD_ERROR,
    SAVE_PRIVATE_KEY,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_RESPONSE,
    CHANGE_PASSWORD_ERROR,
    VALIDATE_PRIVATE_KEY,
    VALIDATE_PRIVATE_KEY_RESPONSE,
    VALIDATE_PRIVATE_KEY_ERROR,
    SAVE_PK_TO_CLOUD,
    SAVE_PK_TO_CLOUD_RESPONSE,
    SAVE_PK_TO_CLOUD_ERROR,
    SEND_PRIVATE_KEY_BY_EMAIL,
    SEND_PRIVATE_KEY_BY_EMAIL_RESPONSE,
    SEND_PRIVATE_KEY_BY_EMAIL_ERROR,
    SAVE_OLD_PRIVATE_KEY,
} from './profile.actions';
import { LOGIN_RESPONSE } from '../session/session.actions';
import { REHYDRATE } from 'redux-persist/es/constants';
import { CREATE_REGISTRAR_RESPONSE } from '../registrar/registrar.actions';
import { ACCEPT_TERMS_AND_CONDITIONS_RESPONSE } from '../common/common.actions';
import { changeToSavedKeysTypeMap } from '../utils/maps';
import { CUSTOM_USER_GROUPS } from '../common/components/SideBarScreen/const';
import { GET_PATIENTS_RESPONSE } from '../patient/patient.actions';

const initialState = {
    user: {},
    // UI related
    updateUserStatus: { error: false, loading: false, success: false, errorMessage: '' },
    saveKeysStatus: { error: false, loading: false, success: false, errorMessage: '' },
    validatePasswordStatus: { error: false, loading: false, success: false, errorMessage: '' },
    changePasswordStatus: { error: false, loading: false, success: false, errorMessage: '' },
    validatePrivateKeyStatus: { error: false, loading: false, success: false, errorMessage: '' },
    savePKToCloudStatus: { error: false, loading: false, success: false, errorMessage: '' },
    sendPrivateKeyByEmailStatus: { error: false, loading: false, success: false, errorMessage: '' },
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_RESPONSE:
            return {
                ...state,
                user: action.response.user,
            };
        case GET_IS_WHITE_LISTER_RESPONSE: {
            return {
                ...state,
                user: {
                    ...state.user,
                    groups: [...state.user.groups, 'whitelister'],
                },
            };
        }
        case GET_PATIENTS_RESPONSE: {
            const hasAnyPatient = (action.response?.results || []).length > 0;
            return {
                ...state,
                user: {
                    ...state.user,
                    groups: hasAnyPatient
                        ? [...state.user.groups, CUSTOM_USER_GROUPS.HAS_ANY_PATIENT]
                        : [...state.user.groups],
                },
            };
        }
        case UPDATE_USER:
            return {
                ...state,
                updateUserStatus: { error: false, loading: true, success: false, errorMessage: '' },
            };
        case UPDATE_USER_RESPONSE:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.response.user,
                },
                updateUserStatus: { error: false, loading: false, success: true, errorMessage: '' },
            };
        case UPDATE_USER_ERROR:
            return {
                ...state,
                updateUserStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case SAVE_KEYS:
            return {
                ...state,
                saveKeysStatus: { error: false, loading: true, success: false, errorMessage: '' },
            };
        case SAVE_KEYS_RESPONSE:
            return {
                ...state,
                saveKeysStatus: { error: false, loading: false, success: true, errorMessage: '' },
                user: {
                    ...state.user,
                    privateKey: action.response.privateKey, // TODO remove field
                    /*
                     * If the user is an 'OS user' and the OS didn't have a public key created, after public key submit,
                     * change the user type to a common 'OS user' ("O") to be able to do all the user specific actions.
                     * The same logic is used for the 'Register user'.
                     * */
                    type: changeToSavedKeysTypeMap[state.user.type] || state.user.type,
                },
            };
        case SAVE_PRIVATE_KEY:
            return {
                ...state,
                user: {
                    ...state.user,
                    /*
                     * If the user is an 'OS user' and the user didn't have a private key (but the OS public key already
                     * exist) after saving the private key change the user type to a common 'OS user' ("O") to be able
                     * to do all the user specific actions.
                     * The same logic is used for the 'Register user'.
                     * */
                    type: changeToSavedKeysTypeMap[state.user.type] || state.user.type,
                },
            };
        case SAVE_OLD_PRIVATE_KEY:
            return {
                ...state,
                user: {
                    ...state.user,
                    oldPrivateKey: action.privateKey,
                },
            };
        case SAVE_KEYS_ERROR:
            return {
                ...state,
                saveKeysStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case VALIDATE_PASSWORD:
            return {
                ...state,
                validatePasswordStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case VALIDATE_PASSWORD_RESPONSE:
            return {
                ...state,
                validatePasswordStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case VALIDATE_PASSWORD_ERROR:
            return {
                ...state,
                validatePasswordStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case CREATE_REGISTRAR_RESPONSE:
            return {
                ...state,
                user: action.response.user,
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                changePasswordStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case CHANGE_PASSWORD_RESPONSE:
            return {
                ...state,
                changePasswordStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                changePasswordStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case VALIDATE_PRIVATE_KEY:
            return {
                ...state,
                validatePrivateKeyStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case VALIDATE_PRIVATE_KEY_RESPONSE:
            return {
                ...state,
                validatePrivateKeyStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case VALIDATE_PRIVATE_KEY_ERROR:
            return {
                ...state,
                validatePrivateKeyStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case SAVE_PK_TO_CLOUD:
            return {
                ...state,
                savePKToCloudStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case SAVE_PK_TO_CLOUD_RESPONSE:
            return {
                ...state,
                savePKToCloudStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case SAVE_PK_TO_CLOUD_ERROR:
            return {
                ...state,
                savePKToCloudStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case SEND_PRIVATE_KEY_BY_EMAIL:
            return {
                ...state,
                sendPrivateKeyByEmailStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case SEND_PRIVATE_KEY_BY_EMAIL_RESPONSE:
            return {
                ...state,
                sendPrivateKeyByEmailStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case SEND_PRIVATE_KEY_BY_EMAIL_ERROR:
            return {
                ...state,
                sendPrivateKeyByEmailStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case ACCEPT_TERMS_AND_CONDITIONS_RESPONSE:
            return {
                ...state,
                user: {
                    ...state.user,
                    hasAcceptedTermsAndConditions: true,
                },
            };
        case LOGOUT:
            return { ...initialState };
        case REHYDRATE:
            let incoming = action.payload;
            if (incoming) {
                return {
                    user: localStorage['token'] ? incoming.profile.user : undefined,
                    updateUserStatus: initialState.updateUserStatus,
                    saveKeysStatus: initialState.saveKeysStatus,
                    validatePasswordStatus: initialState.validatePasswordStatus,
                    changePasswordStatus: initialState.changePasswordStatus,
                    validatePrivateKeyStatus: initialState.validatePrivateKeyStatus,
                    savePKToCloudStatus: initialState.savePKToCloudStatus,
                    sendPrivateKeyByEmailStatus: initialState.sendPrivateKeyByEmailStatus,
                };
            } else {
                return state;
            }
        default:
            return state;
    }
};

export default profileReducer;

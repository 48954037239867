import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { UserTypes } from '../../constants';

/*
 * This returns the default main page depending of the user type
 * */
const getDefaultRedirectPath = (userType) => {
    switch (userType) {
        case UserTypes.OS_USER:
            return '/dashboard';
        case UserTypes.REGISTER_USER:
            return '/registration';
        case UserTypes.DRUGSTORE_WITHOUT_PUBLIC_KEY:
        case UserTypes.OS_USER_WITHOUT_PUBLIC_KEY:
        case UserTypes.OS_USER_WITHOUT_PRIVATE_KEY:
        case UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY:
        case UserTypes.AUDITOR_WITHOUT_PUBLIC_KEY:
        case UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY:
        case UserTypes.AUDITOR_WITHOUT_OLD_PRIVATE_KEY:
        case UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY:
            return '/profile';
        case UserTypes.USER:
            return '/registrarNewAccount';
        case UserTypes.AUDITOR:
            return '/auditorTransactions';
        case UserTypes.REGISTER_USER_WITHOUT_PUBLIC_KEY:
        case UserTypes.REGISTER_USER_WITHOUT_PRIVATE_KEY:
            return '/registrarProfile';
        case UserTypes.ROCHE_USER:
        case UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY:
            return '/rocheTransactions';
        case UserTypes.DRUGSTORE:
            return '/dashboard';
        default:
            return '/';
    }
};

const LoginRequiredRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                !rest.loggedIn || getDefaultRedirectPath(rest.userType) === rest.path ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: getDefaultRedirectPath(rest.userType),
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default connect((state) => ({
    loggedIn: state.session.isLoggedIn,
    userType: state.profile.user?.type,
}))(LoginRequiredRoute);

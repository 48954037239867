import React, { useState } from 'react';
import Card from '../../../common/components/Card/Card';
import { ScreenDetails } from '../PatientEvidenceScreen/PatientEvidenceScreen';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import '../EditPatientScreen/EditPatientScreen.scss';
import './PatientDoseScreen.scss';

import BreadCrumbs from '../../../common/components/BreadCrumbs/BreadCrumbs';

const DoseBlock = ({ doses, cycle }) => {
    // Function que calcula la diferencia de dias
    const getDateOffset = (date1, date2) => {
        const difference = Math.abs(date1 - date2);
        const days = difference / (1000 * 3600 * 24);
        return `${days}`;
    };

    const getVariations = (dosis_programada, dosis_real) => {
        if (dosis_real && dosis_programada > 0) {
            const porcentage = ((dosis_real - dosis_programada) * 100) / dosis_programada;
            return porcentage.toFixed(2) + '%';
        } else if (dosis_real < dosis_programada && dosis_programada > 0 && dosis_real > 0) {
            const porcentage = ((dosis_programada - dosis_real) * 100) / dosis_programada;
            return `-${porcentage.toFixed(2)}%`;
        } else {
            return '';
        }
    };

    return (
        <>
            {doses?.map((d, i) => {
                return (
                    <tr key={i} className={d.ciclo === cycle ? 'selected' : ''}>
                        <td>{d.ciclo}</td>
                        <td>{d.dia}</td>
                        <td>{d.fecha ? d.fecha : '-'}</td>
                        <td>{d.dosis_programada}</td>
                        <td>{d.dosis_real}</td>
                        <td>{d.fecha_registracion ? d.fecha_registracion : '-'}</td>
                        <td>{getVariations(d.dosis_programada, d.dosis_real)}</td>
                        <td>
                            {d.fecha_registracion && d.fecha
                                ? getDateOffset(new Date(d.fecha_registracion), new Date(d.fecha))
                                : '-'}
                        </td>
                    </tr>
                );
            })}
            {/*<tr>
        <th colSpan={3}>Subtotal de control (tolerancia dosis 10%)</th>
        <th>{e_subtotal}</th>
        <th>{r_subtotal}</th>
        <th>{parseInt((r_subtotal-e_subtotal)/e_subtotal*100)}%</th>
        <th>Auditar</th>
    </tr>*/}
        </>
    );
};

export const Doses = ({ programedDoses, cycle }) => {
    return programedDoses?.dosis?.length > 0 ? (
        <table className='dosesTable'>
            <thead>
                <tr>
                    <th colSpan={4}>{I18n.t('editPatient.doses.doses')}</th>
                    <th rowSpan={2}>{I18n.t('editPatient.doses.mgReg')}</th>
                    <th rowSpan={2}>
                        {I18n.t('editPatient.doses.dateOf')}
                        <br />
                        {I18n.t('editPatient.doses.registration')}
                    </th>
                    <th rowSpan={2}>{I18n.t('editPatient.doses.var')}</th>
                    <th rowSpan={2}>Diferencia de dias</th>
                </tr>
                <tr>
                    <th>{I18n.t('editPatient.doses.cycle')}</th>
                    <th>{I18n.t('editPatient.doses.day')}</th>
                    <th>{I18n.t('editPatient.doses.estDate')}</th>
                    <th>{I18n.t('editPatient.doses.mgTotal')}</th>
                </tr>
            </thead>
            <tbody>
                <DoseBlock doses={programedDoses.dosis} cycle={cycle} />
            </tbody>
        </table>
    ) : (
        <div className='error-text'>No se encontraron dosis</div>
    );
};

const PatientDoseScreen = ({ patient, contract, programedDoses, patientDetail, userType }) => {
    const [patientAux, setPatientAux] = useState(patient);

    React.useEffect(() => {
        if (userType === 'AUDITOR') {
            setPatientAux({
                ...patient,
                affiliate: patientDetail._original.paciente.afiliado,
                dni: patientDetail._original.paciente.dni,
                id: patientDetail._original.paciente.id,
                name: patientDetail._original.paciente.nombre,
                birthDate: patientDetail._original.paciente.fecha_nacimiento,
                sex: patientDetail._original.paciente.sexo,
                weight: [
                    {
                        effdt: null,
                        id: 1,
                        peso: patientDetail._original.paciente.peso?.toString(),
                        _id: 1,
                    },
                ],
                contracts: [
                    {
                        contrato: patientDetail._original.contrato.id,
                        descripcion: patientDetail._original.contrato.descripcion,
                        id: patientDetail._original.paciente_contrato,
                        indicacion_descr: patientDetail._original.contrato.indicacion_descr,
                        label: patientDetail._original.contrato.descripcion,
                        subtipo: patientDetail._original.contrato.indicacion_descr,
                        tipo_contrato: patientDetail._original.contrato.tipo_contrato,
                        _id: patientDetail._original.paciente_contrato,
                        evidencia: patientDetail._original.contrato.evidencia,
                    },
                ],
            });
        }
    }, [patientDetail]);
    return (
        <div className='i-container'>
            <BreadCrumbs extraLinks={[{ label: I18n.t('editPatient.brcm.programedDoses') }]} />

            <Card title={I18n.t('editPatient.brcm.programedDoses')} className='side-padding-m'>
                <br />
                <ScreenDetails patient={patientAux} contract={contract} />
                <br />
                <br />
                <Doses programedDoses={programedDoses} />
            </Card>
        </div>
    );
};

const mapStateToProps = (state) => ({
    patient: state.patient.editionDetails,
    contract: state.contract.selectedContract,
    programedDoses: state.patient.programedDoses,
    patientDetail: state.transaction.auditorPatientDetail,
    userType: state.profile.user.type,
});

const mapDispatchToProps = (dispatch) => ({
    // TODO
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientDoseScreen);

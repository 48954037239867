import React from 'react';
import './SearchBar.scss';
import Input from '../Input';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';

const SearchBar = (props) => {
    return (
        <div className={props.className ?? 'search-bar'}>
            <Input
                value={props.textToSearch}
                placeholder={props.placeholder || I18n.t('common.searchPlaceholder')}
                labelClassName={props.labelClassName}
                type='text'
                handleChange={props.onSearch}
                icon={<i className={`icon-magnifier ${props.iconStyle}`} />}
                pillStyle={props.pillStyle}
            />
        </div>
    );
};

SearchBar.propTypes = {
    className: PropTypes.string.isRequired,
    textToSearch: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    pillStyle: PropTypes.string,
};

export default SearchBar;

import { webApi, get, put, post } from '../utils/http';
import { gasPrice, loadWeb3, maxGasForTokenSend, newTokenContract } from '../utils/wallet';
import { nex_abi } from '../wallet/containers/abi';

export default {
    getTransactions: (statusFilter, page) =>
        get(webApi + `api/transacciones/${statusFilter || ''}`),
    getTransactionsFilter: (statusFilter, page, searchText, startDate, endDate, order) => {
        let result = [];
        if (statusFilter)
            result.push(`estado=${statusFilter === 'emptyValue' ? '' : statusFilter}`);
        if (searchText) result.push(`search_text_str=${searchText}`);
        if (startDate) result.push(`start_date_str=${startDate}`);
        if (endDate) result.push(`end_date_str=${endDate}`);
        if (page) result.push(`page=${page}`);
        if (order) result.push(order);
        const queryString = result.length > 0 ? `?${result.join('&')}` : '';
        return get(webApi + `api/transacciones/${queryString}`);
    },
    getTransactionsEmitted: () => get(webApi + `api/blockchain/transacciones/emitidas/`),
    getTransactionsReceived: () => get(webApi + `api/blockchain/transacciones/recibidas/`),
    getOsAuditTransactions: () => get(webApi + `api/auditoria/`),
    getAuditorTransactions: () => get(webApi + `api/auditor/me/registros/`),
    getSharedRiskTransactions: () => get(webApi + `api/riesgocompartido`),
    getTransactionMonitorTransactions: (statusFilter) =>
        get(webApi + `api/interfacelog/${statusFilter || ''}`),
    postAuditorTransactions: (body, id) => post(webApi + `api/auditoria/${id}/historial/`, body),
    updateAuditorTransactions: (body, id) => put(webApi + `api/auditoria/${id}/historial/`, body),
    updateSharedRiskTransactions: (body, id) => put(webApi + `api/riesgocompartido/${id}/`, body),
    putTransactionsAplications: (body) =>
        put(webApi + `api/transacciones/cambiaraplicacion/`, body),
    validateTransactions: (body) => put(webApi + `api/transacciones/validar/`, body),
    rejectTransactions: (body) => put(webApi + `api/transacciones/rechazar/`, body),
    getNexMonitorTransactions: (statusFilter) =>
        get(webApi + `api/blockchain/transacciones/${statusFilter || ''}`),
    validatePayments: (body) => put(webApi + 'api/transacciones/iniciarpago/', body),
    pay: (body) => put(webApi + `api/transacciones/registrarpago/`, body),
    trackPaymentsErrors: (body) => put(webApi + `api/transacciones/resetpago/`, body),
    sendDataToSAP: (body) => put(webApi + `api/blockchain/contabilizar-recepcion/`, body),
    resendDataToSAP: (body) => put(webApi + `api/reenviarsap/`, body),
    getGrantingMonitorAddresses: () => get(webApi + 'api/blockchain/addresses/'),

    grantAddresses: (body) => post(webApi + 'api/blockchain/grantaddresses/', body),
    getPatientAuditor: (id) => get(webApi + `api/auditoria/${id}/`),
    getPatientEvidenceAuditor: (id) =>
        get(webApi + `api/auditoria/pacientecontrato/${id}/evidencias`),

    getWhiteListedAddresses: (address, fundsKey) => {
        const { tokenContract } = newTokenContract(fundsKey);
        return new Promise((resolve, reject) => {
            tokenContract.methods.getWhitelisted().call(
                // { from: address, gas: maxGasForTokenSend, gasPrice },
                { from: address, gas: '2000000', gasPrice },
                (error, whitelisted) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(whitelisted);
                    }
                },
            );
        });
    },

    addressIsWhiteLister: (address, fundsKey) => {
        const { tokenContract } = newTokenContract(fundsKey);
        return new Promise((resolve, reject) => {
            tokenContract.methods.isWhiteLister(address).call((error, receipt) => {
                if (error) {
                    reject(error);
                } else {
                    if (!receipt) reject('Address no válida');
                    resolve(receipt);
                }
            });
        });
    },

    addToWhitelisted: (addressToAdd, address, fundsKey) => {
        const { tokenContract } = newTokenContract(fundsKey);
        return new Promise((resolve, reject) => {
            tokenContract.methods
                .addToWhitelisted(addressToAdd)
                .send({ from: address, gas: maxGasForTokenSend, gasPrice })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                })
                .on('error', function (error) {
                    reject(error);
                });
        });
    },

    transferJAC: (addressGrant, fundsKey, address) => {
        const web3 = loadWeb3(fundsKey);
        const txObject = {
            from: address, // Roche
            to: addressGrant, // Address a grantear
            value: web3.utils.toWei('0.5', 'ether'),
            gas: maxGasForTokenSend,
            gasPrice,
        };
        return new Promise((resolve, reject) => {
            web3.eth
                .sendTransaction(txObject)
                .on('receipt', function (receipt) {
                    resolve(receipt);
                })
                .on('error', function (error) {
                    reject(error);
                });
        });
    },

    transferJacDirectPayment: (address, fundsKey, addressPD) => {
        const web3 = loadWeb3(fundsKey);
        const txObject = {
            from: address,
            to: addressPD,
            value: web3.utils.toWei('0.5', 'ether'),
            gas: maxGasForTokenSend,
            gasPrice,
        };
        return new Promise((resolve, reject) => {
            web3.eth
                .sendTransaction(txObject)
                .on('error', function (error) {
                    reject(error);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                });
        });
    },

    grantDirectPayment: (address, addressPD, fundsKeyPD) => {
        const { tokenContract: tokenContractPD } = newTokenContract(fundsKeyPD);
        const spender = address;
        const bigAllowance = '999999999000000000000000000';
        return new Promise((resolve, reject) => {
            tokenContractPD.methods
                .increaseAllowance(spender, bigAllowance)
                .send({ from: addressPD, gas: maxGasForTokenSend, gasPrice })
                .on('error', function (error) {
                    reject(error);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                });
        });
    },

    postSendNex: (addAddress, amount, address, fundsKey, nonce) => {
        return new Promise((resolve, reject) => {
            const { w3, tokenContract } = newTokenContract(fundsKey);
            tokenContract.methods
                .transfer(addAddress, w3.utils.toWei(amount, 'ether'))
                .send({ from: address, gas: maxGasForTokenSend, gasPrice, nonce })
                .on('transactionHash', function (hash) {})
                .on('error', function (error) {
                    reject(error);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                });
        });
    },
    postMultipleSendsNex: (addAddress, amount, address, fundsKey, nonce) => {
        const w3 = loadWeb3(fundsKey);
        return new Promise((resolve, reject) => {
            const tokenContract = new w3.eth.Contract(nex_abi, process.env.REACT_APP_TOKEN_NEX);
            tokenContract.methods
                .transfer(addAddress, w3.utils.toWei(amount, 'ether'))
                .send({ from: address, gas: maxGasForTokenSend, gasPrice, nonce })
                .on('transactionHash', function (hash) {})
                .on('error', function (error) {
                    reject(error);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                });
        });
    },
};

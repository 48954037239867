import moment from 'moment';
import { I18n } from 'react-redux-i18n';

export const getDate = (date) => {
    if (!date) return undefined;
    date = new Date(date);
    date.setHours(24);
    return date;
};
export const isValidDate = (dateString) => {
    let timestamp = Date.parse(dateString);
    return !isNaN(timestamp);
};

export const dateStr = (date) =>
    isValidDate(date)
        ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
              .toISOString()
              .split('T')[0]
        : '';

export const formatDateStr = (date) => {
    if (!date) return undefined;
    const split = date.split('-');
    return `${split[2]}/${split[1]}/${split[0]}`;
};
export const minimalistDate = (date) => {
    if (!date) return undefined;
    if (!date.isDate) date = new Date(date);
    return `${date.getDate()} ${getMonthName(date.getMonth())} ${date.getFullYear()} `;
};
export const todayDate = new Date();

export const todayStr = dateStr(todayDate);

export const getMonthName = (monthNumber) => {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    return I18n.t(`utils.months.${months[monthNumber]}`);
};

import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Redirect, Route } from 'react-router';
import actions from '../../actions';
import { UserTypes } from '../../constants';
import { openTermsAndConditions } from '../../utils';
import TermsAndConditionsModal from './TermsAndConditionsModal';

const LoginRequiredRoute = ({ component, ...rest }) => {
    if (!rest.loggedIn) {
        rest.saveRedirectRoute(rest.location.pathname);
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                rest.loggedIn ? (
                    !rest.hasAcceptedTermsAndConditions &&
                    rest.userType !== UserTypes.USER &&
                    rest.userType !== UserTypes.ROCHE_USER &&
                    rest.userType !== UserTypes.DRUGSTORE_WITHOUT_PUBLIC_KEY &&
                    rest.userType !== UserTypes.DRUGSTORE ? (
                        <TermsAndConditionsModal
                            show={true}
                            title={I18n.t('termsAndConditionsModal.title')}
                            titleChk={I18n.t('termsAndConditionsModal.acceptThe')}
                            titleRedirect={I18n.t('termsAndConditionsModal.termsAndConditions')}
                            redirect={openTermsAndConditions}
                            onSubmit={rest.acceptTermsAndConditions}
                            loading={rest.acceptTermsAndConditionsStatus.loading}
                        />
                    ) : (
                        component(props)
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default connect(
    (state) => ({
        loggedIn: state.session.isLoggedIn,
        hasAcceptedTermsAndConditions: state.profile.user.hasAcceptedTermsAndConditions,
        userType: state.profile.user.type,
        acceptTermsAndConditionsStatus: state.common.acceptTermsAndConditionsStatus,
    }),
    (dispatch) => ({
        saveRedirectRoute: (location) => dispatch(actions.session.saveRedirectRoute(location)),
        acceptTermsAndConditions: () => dispatch(actions.common.acceptTermsAndConditions()),
    }),
)(LoginRequiredRoute);
